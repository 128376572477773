.arrowAnimation {
  transition: transform 300ms;
}

a:hover {
  .arrowAnimation {
    transform: translateX(calc(var(--design-spacing-xl) - var(--design-spacing-lg)));
  }
}

.linkWithBorder {
  border-bottom: var(--design-border-sm) solid var(--design-color-border-action-default);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--design-border-sm);
    background-color: var(--design-color-border-action-hover);
    display: none;
  }

  &:hover::after {
    display: block;
  }
}